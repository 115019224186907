import { Fragment, HTMLAttributes } from 'react'

import { ExternalLink } from './ExternalLink'
import PageLink from './PageLink'
import PdfLink from './PdfLink'
import { VideoLink } from './VideoLink'

interface Props extends HTMLAttributes<HTMLElement> {
  data:
    | Queries.ExternalLinkFragment
    | Queries.PageLinkFragment
    | Queries.PdfLinkFragment
    | Queries.VideoLinkFragment
    | null
    | undefined
}

export const DatoLink = ({ data, ...props }: Props): JSX.Element => {
  switch (data?.__typename) {
    case 'DatoCmsExternalLink':
      return (
        <ExternalLink
          data={data}
          {...props}
        />
      )
    case 'DatoCmsPageLink':
      return (
        <PageLink
          data={data}
          {...props}
        />
      )
    case 'DatoCmsPdfLink':
      return (
        <PdfLink
          data={data}
          {...props}
        />
      )
    case 'DatoCmsVideoLink':
      return (
        <VideoLink
          data={data}
          {...props}
        />
      )
    default:
      return <Fragment />
  }
}
