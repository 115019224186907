import { ComponentPropsWithoutRef, Fragment } from 'react'

import { useLocalFileUrl } from '@/hooks/useLocalFileUrl'

import { LightboxLink } from './LightboxLink'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data: Queries.NewsArticleFragment | null
}

export const NewsArticleLink = ({
  data,
  children,
  ...props
}: Props): JSX.Element => {
  const { content } = data || {}
  const pdfUrl = useLocalFileUrl(
    content?.__typename === 'DatoCmsPdf'
      ? content.pdf?.localFileId
      : null
  )
  switch (content?.__typename) {
    case 'DatoCmsUrl': {
      return (
        <a
          href={content.url || undefined}
          target="_blank"
          rel="noreferrer"
          {...props}
        >
          {children}
        </a>
      )
    }
    case 'DatoCmsPdf': {
      return (
        <a
          href={pdfUrl || undefined}
          target="_blank"
          rel="noreferrer"
          {...props}
        >
          {children}
        </a>
      )
    }
    case 'DatoCmsNewsArticleBody': {
      return (
        <LightboxLink
          data={data}
          layout="FULL"
          {...props}
        >
          {children}
        </LightboxLink>
      )
    }
    default:
      return <Fragment />
  }
}
