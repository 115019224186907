import { ReactNode } from 'react'

import { LightboxContextProvider } from '../lightbox-context'
import { NavContextProvider } from '../nav-context'

export const ContextWrapper = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  return (
    <LightboxContextProvider>
      <NavContextProvider>{children}</NavContextProvider>
    </LightboxContextProvider>
  )
}
