import { css } from '@emotion/react'
import { Fragment } from 'react'

import {
  NewsArticle,
  PersonArticle,
  VideoArticle,
} from '@/features/article-templates'

export type LightboxContentData =
  | Queries.PersonFragment
  | Queries.VideoArticleFragment
  | Queries.NewsArticleFragment

interface Props {
  data: LightboxContentData | null
}
export const LightboxContent = ({ data }: Props): JSX.Element => {
  const style = css`
    grid-area: 1 / 1 / 2 / 2;
  `
  switch (data?.__typename) {
    case 'DatoCmsPerson':
      return (
        <PersonArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    case 'DatoCmsVideoArticle':
      return (
        <VideoArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    case 'DatoCmsNewsArticle':
      return (
        <NewsArticle
          data={data}
          layout="LIGHTBOX"
          css={style}
        />
      )
    default:
      return <Fragment />
  }
}
