exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-news-article-page-tsx": () => import("./../../../src/templates/NewsArticlePage.tsx" /* webpackChunkName: "component---src-templates-news-article-page-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/NewsPage.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-person-article-page-tsx": () => import("./../../../src/templates/PersonArticlePage.tsx" /* webpackChunkName: "component---src-templates-person-article-page-tsx" */),
  "component---src-templates-video-article-page-tsx": () => import("./../../../src/templates/VideoArticlePage.tsx" /* webpackChunkName: "component---src-templates-video-article-page-tsx" */)
}

