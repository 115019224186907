import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { LightboxLink } from './LightboxLink'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data: Queries.VideoLinkFragment | null
}

export const VideoLink = ({
  data,
  children,
  ...props
}: Props): JSX.Element => {
  return (
    <LightboxLink
      data={data?.video || null}
      layout="VIDEO"
      {...props}
    >
      {children || data?.linkText}
    </LightboxLink>
  )
}

export const VideoLinkFragment = graphql`
  fragment VideoLink on DatoCmsVideoLink {
    __typename
    id: originalId
    linkText
    video {
      ...VideoArticle
    }
  }
`
