// Modified StructuredText component to handle Gatsby's incompatible type generation
import { isLink } from 'datocms-structured-text-utils'
import { Link } from 'gatsby'
import {
  StructuredText,
  StructuredTextDocument,
  StructuredTextGraphQlResponseRecord,
  StructuredTextPropTypes,
  renderNodeRule,
} from 'react-datocms/structured-text'

interface Props<
  R1 extends StructuredTextGraphQlResponseRecord,
  R2 extends StructuredTextGraphQlResponseRecord = R1,
> extends Omit<StructuredTextPropTypes<R1, R2>, 'data'> {
  data: any
  // | {
  //     readonly value: Record<string, unknown> | null
  //     blocks?: R1[]
  //     links?: R2[]
  //   }
  // | null
  // | undefined
}

export const DatoStructuredText = <
  R1 extends StructuredTextGraphQlResponseRecord,
  R2 extends StructuredTextGraphQlResponseRecord = R1,
>({
  data,
  ...props
}: Props<R1, R2>): JSX.Element | undefined => {
  if (data) {
    return (
      <StructuredText
        data={
          data as {
            value: StructuredTextDocument
            blocks?: R1[]
            links?: R2[]
          }
        }
        customNodeRules={[
          renderNodeRule(isLink, ({ node, children, key }) => {
            const isInternal = /^\/(?!\/)/.test(node.url)
            if (isInternal) {
              return (
                <Link
                  to={node.url}
                  key={key}
                  {...node.meta}
                >
                  {children}
                </Link>
              )
            } else
              return (
                <a
                  key={key}
                  href={node.url}
                  target="_blank"
                  rel="noreferrer"
                  {...node.meta}
                >
                  {children}
                </a>
              )
          }),
        ]}
        {...props}
      />
    )
  }
}
