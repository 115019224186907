import { graphql } from 'gatsby'
import { HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  data: Queries.ExternalLinkFragment | null
}

export const ExternalLink = ({
  data,
  ...props
}: Props): JSX.Element => {
  return (
    <a
      href={data?.url || undefined}
      target="__blank"
      rel="noopener"
      {...props}
    >
      {data?.linkText}
    </a>
  )
}

export const ExternalLinkFragment = graphql`
  fragment ExternalLink on DatoCmsExternalLink {
    __typename
    id: originalId
    linkText
    url
  }
`
