import { useCallback, useEffect, useMemo, useRef } from 'react'

interface Props {
  elements: (HTMLElement | null)[]
  condition: boolean
}

export const useFocusTrap = ({ elements, condition }: Props) => {
  const focusableElementTypes =
    'button, [href], input:not([tabindex="-1"]), select, textarea, [tabindex]:not([tabindex="-1"])'

  const focusableElements = useMemo(() => {
    if (condition) {
      const elementsSet = new Set([
        ...elements.flatMap(element => {
          const matchedElements = element?.querySelectorAll(
            focusableElementTypes
          )
          return matchedElements
            ? Array.from(matchedElements as NodeListOf<HTMLElement>)
            : []
        }),
      ])
      return Array.from(elementsSet)
    }
  }, [condition, elements, focusableElementTypes])

  const isFocusable = focusableElements?.length

  const activeTabIndex = useRef(0)

  useEffect(() => {
    if (isFocusable) {
      activeTabIndex.current = -1
    }
  }, [focusableElements, isFocusable])

  const tabFunction = useCallback(
    (e: KeyboardEvent) => {
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9
      if (isFocusable && isTabPressed) {
        e.preventDefault()
        // if shift key pressed for shift + tab combination
        if (e.shiftKey) {
          // if focus has reached the first focusable element then go to last
          if (activeTabIndex.current === 0) {
            activeTabIndex.current = focusableElements.length - 1
          } else {
            activeTabIndex.current -= 1
          }
        } else {
          // if focus has reached the last focusable element then go to first
          if (activeTabIndex.current === focusableElements.length - 1) {
            activeTabIndex.current = 0
          } else {
            activeTabIndex.current += 1
          }
        }
        focusableElements[activeTabIndex.current].focus()
      }
    },
    [isFocusable, focusableElements]
  )

  const onFocus = useCallback((index: number) => {
    activeTabIndex.current = index
  }, [])
  useEffect(() => {
    focusableElements?.forEach((element, i) => {
      element.addEventListener('focus', () => onFocus(i), false)
    })
    return () => {
      focusableElements?.forEach((element, i) => {
        element.removeEventListener('focus', () => onFocus(i))
      })
    }
  }, [focusableElements, onFocus])

  useEffect(() => {
    document.addEventListener('keydown', tabFunction, false)
    return () => {
      document.removeEventListener('keydown', tabFunction)
    }
  })
}
