import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { useLocalFileUrl } from '@/hooks/useLocalFileUrl'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data: Queries.PdfLinkFragment | null
}

const PdfLink = ({ data, ...props }: Props): JSX.Element => {
  const url = useLocalFileUrl(data?.pdf?.localFileId)

  return (
    <a
      href={url || undefined}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      <span>{data?.linkText}</span>
    </a>
  )
}

export const PdfLinkFragment = graphql`
  fragment PdfLink on DatoCmsPdfLink {
    id: originalId
    __typename
    pdf {
      localFileId
    }
    linkText
  }
`

export default PdfLink
