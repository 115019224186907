import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, useState } from 'react'
import ReactPlayer from 'react-player'

import { aspectRatio } from '@/theme/mixins'

import { VideoPlayIcon } from './VideoPlayIcon'

interface Props extends ComponentPropsWithoutRef<'article'> {
  data: Queries.VideoArticleFragment | null
  layout: 'LIGHTBOX' | 'PAGE'
}

export const VideoArticle = ({
  data,
  layout,
  ...props
}: Props): JSX.Element => {
  const [playing, setPlaying] = useState(false)
  const styles = {
    article: css`
      display: grid;
      justify-items: center;
    `,
    playerContainer: css`
      width: calc(100vw - 2 * var(--lightbox-margin));
      box-sizing: border-box;
      background: #fff;
      max-width: calc(
        (100vh - 2 * var(--vertical-margin)) *
          (${data?.video?.width} / (${data?.video?.height}))
      );
      max-height: calc(100vh - 2 * var(--vertical-margin));
      ${layout === 'PAGE' &&
      css`
        box-sizing: content-box;
        width: 100%;
        padding: 0 var(--margin) var(--row-36);
        max-width: calc(
          (100svh - var(--nav-height) - var(--row-36)) *
            (${data?.video?.width} / (${data?.video?.height}))
        );
        max-height: calc(100svh - var(--nav-height) - var(--row-36));
      `}
    `,
    player: css`
      ${aspectRatio(
        (data?.video?.width || 0) / (data?.video?.height || 0)
      )}
    `,
  }
  return (
    <article
      css={styles.article}
      {...props}
    >
      <div css={styles.playerContainer}>
        <ReactPlayer
          url={data?.video?.url || undefined}
          light={data?.video?.thumbnailUrl || true}
          playIcon={<VideoPlayIcon />}
          width="100%"
          height="100%"
          css={styles.player}
          onClickPreview={() => setPlaying(true)}
          playing={playing}
          controls
        />
      </div>
    </article>
  )
}

export const VideoArticleFragment = graphql`
  fragment VideoArticle on DatoCmsVideoArticle {
    __typename
    id: originalId
    video {
      url
      thumbnailUrl
      width
      height
    }
    slug
    seoMetaTags {
      tags
    }
  }
`
