import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { rgba } from 'polished'
import { ComponentPropsWithoutRef } from 'react'

import { MultimediaBlock } from '@/features/carousel'
import { DatoStructuredText } from '@/features/common-blocks'
import { firstChild } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data: Queries.NewsArticleBodyFragment | null
}

export const NewsArticleBody = ({
  data,
  ...props
}: Props): JSX.Element => {
  const styles = {
    container: css`
      > * {
        margin-left: var(--margin);
        margin-right: var(--margin);
      }
      > ${firstChild} {
        margin-top: 0;
      }
      > {
        h2,
        h3,
        h4,
        p,
        ul,
        ol,
        blockquote {
          max-width: var(--max-width);
        }
      }
      h2 {
        margin: 1.5em var(--margin) 0.5em;
        font-size: var(--fs-24);
        font-family: var(--ff-good-wide);
        font-weight: 700;
      }
      h3 {
        margin: 2em var(--margin) 0.5em;
        font-size: var(--fs-18);
        font-family: var(--ff-good-wide);
        font-weight: 600;
        color: ${colors.navyMid};
      }
      h2 + h3 {
        margin-top: 1em;
      }
      h4 {
        margin: 2em var(--margin) 0.5em;
        font-size: var(--fs-15);
        font-family: var(--ff-good-wide);
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.05em;
      }
      h2 + h4,
      h3 + h4 {
        margin-top: 1.5em;
      }
      p,
      ul,
      ol {
        color: ${colors.gray30};
        margin: 0.75em var(--margin);
      }
      a {
        color: var(--link-color, ${colors.teal});
        transition: color 300ms ease;
        @media (hover: hover) {
          &:hover {
            color: var(--link-color-hover, ${colors.navy});
          }
        }
      }
      ul,
      ol {
        padding-inline-start: 1.5em;
        margin-block: 0.5em 1.25em;
        box-sizing: border-box;
        li {
          padding-inline-start: 0.25em;
          ::marker {
            color: ${colors.blueMid};
            font-weight: 500;
          }
          p {
            line-height: 1.5;
            margin: 0 0 0.75em;
          }
        }
      }
      b,
      strong {
        font-weight: 625;
      }
      hr {
        border: none;
        height: 0.5rem;
        background: rgba(0, 0, 0, 0.05);
        margin: 2em 0;
      }
      > * {
        &:last-child {
          margin-bottom: 0;
        }
      }
      blockquote {
        display: block;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        margin: 1.25em 0 1.5em;
        padding: 0.5em 2em 1em;
        background-color: rgba(0, 0, 0, 0.05);
        font-size: var(--fs-21);
        font-style: italic;
        color: ${rgba(colors.gray20, 0.8)};
        &:before {
          content: '“';
          display: block;
          position: absolute;
          font-family: var(--ff-display);
          font-style: normal;
          font-size: 300%;
          top: 0em;
          left: 0.2em;
          opacity: 0.333;
        }
        p {
          margin: 0.5em 0;
          &:last-of-type:after {
            content: '”';
            display: inline;
            font-family: var(--ff-display);
            font-style: normal;
            font-size: 150%;
            line-height: 0;
            opacity: 0.5;
          }
        }
        footer {
          font-size: var(--fs-15);
          font-weight: 500;
          color: ${rgba(colors.gray20, 0.67)};
        }
      }
    `,
    multimediaBlock: css`
      margin: 3em 0;
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <DatoStructuredText
        data={data?.body}
        renderBlock={({ record }) => {
          switch (record.__typename) {
            case 'DatoCmsMultimediaBlock': {
              return (
                <MultimediaBlock
                  css={styles.multimediaBlock}
                  data={record as Queries.MultimediaBlockFragment}
                  layout="ARTICLE"
                />
              )
            }
            default:
              return null
          }
        }}
      />
    </div>
  )
}

export const NewsArticleBodyFragment = graphql`
  fragment NewsArticleBody on DatoCmsNewsArticleBody {
    __typename
    id: originalId
    body {
      value
      blocks {
        ... on DatoCmsMultimediaBlock {
          ...MultimediaBlock
        }
      }
    }
  }
`
