import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react'

interface IContext {
  lightboxOpen: boolean
  setLightboxOpen: Dispatch<SetStateAction<boolean>>
}

const LightboxContext = createContext<IContext | undefined>(undefined)

export const useLightboxContext = () =>
  useContext(LightboxContext) as IContext

export const LightboxContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false)
  return (
    <LightboxContext.Provider
      value={{
        lightboxOpen,
        setLightboxOpen: value => setLightboxOpen(value),
      }}
    >
      {children}
    </LightboxContext.Provider>
  )
}
