import { css } from '@emotion/react'
import { PageProps } from 'gatsby'
import { Fragment, ReactElement } from 'react'

import { useNavContext } from '@/contexts/nav-context'
import { GlobalStyles } from '@/theme/GlobalStyles'

import { ContactBar } from './ContactBar'
import { Footer } from './Footer'
import { Nav } from './Nav'

type Props = {
  children: ReactElement
  pageProps: PageProps
}

export const Layout = ({ children }: Props): JSX.Element => {
  const { mobileNavIsOpen } = useNavContext()
  const styles = {
    main: css`
      transition: opacity 300ms ease;
      ${mobileNavIsOpen &&
      css`
        opacity: 0;
      `}
    `,
  }
  return (
    <Fragment>
      <GlobalStyles />
      <Nav />
      <main css={styles.main}>{children}</main>
      <ContactBar />
      <Footer />
      <div id="lightbox-container" />
    </Fragment>
  )
}
