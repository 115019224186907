import { graphql } from 'gatsby'
import { ComponentProps, Fragment } from 'react'
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa'

export interface ISocialLink {
  socialType:
    | 'Facebook'
    | 'Instagram'
    | 'LinkedIn'
    | 'Tiktok'
    | 'Twitter'
    | 'Youtube'
  url: string
}

interface Props extends ComponentProps<'a'> {
  data: Queries.SocialLinkFragment | null
}

export const SocialLink = ({ data, ...props }: Props): JSX.Element => {
  const renderIcon = () => {
    switch (data?.socialType) {
      case 'Facebook':
        return <FaFacebook />
      case 'Instagram':
        return <FaInstagram />
      case 'LinkedIn':
        return <FaLinkedin />
      case 'Tiktok':
        return <FaTiktok />
      case 'Twitter':
        return <FaTwitter />
      case 'Youtube':
        return <FaYoutube />
      default:
        return <Fragment />
    }
  }
  return (
    <a
      href={data?.url || undefined}
      target="_blank"
      rel="noreferrer"
      aria-label={data?.socialType || undefined}
      {...props}
    >
      {renderIcon()}
    </a>
  )
}

export const SocialLinkFragment = graphql`
  fragment SocialLink on DatoCmsSocialLink {
    id: originalId
    __typename
    socialType
    url
  }
`
