export const breakpoints = {
  l: 1440,
  ls: 1260,
  ml: 1080,
  m: 900,
  ms: 720,
  s: 540,
}

export const colors = {
  navyDark: '#00112E',
  navyMid: '#445C8B',
  navy: '#081C43',
  navyLight: '#B8C9E1',
  navyLighter: '#D9E0EA',
  gray20: '#2b353b',
  gray30: '#424f57',
  gray40: '#5a6972',
  gray60: '#889daa',
  gray95: '#f0f3f4',
  blueLight: '#C9DDFF',
  blueLighter: '#E6F0FF',
  blueMid: '#325598',
  teal: '#4097aa',
  tealLight: '#76C8DA',
  greenDark: '#2B685A',
  green: '#66B277',
  greenLight: '#ACC991',
  orangeDark: '#DF5A0E',
  orange: '#DC8229',
  orangeLight: '#F4B061',
}
