import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import ReactPlayer, { ReactPlayerProps } from 'react-player'

import { aspectRatio } from '@/theme/mixins'

import { VideoPlayIcon } from './VideoPlayIcon'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.ExternalVideoBlockFragment | null
  playerProps?: ReactPlayerProps
}

export const ExternalVideoBlock = ({
  data,
  playerProps,
  ...props
}: Props): JSX.Element => {
  const { inView, ref } = useInView({
    rootMargin: '0% -40%',
  })
  const [playing, setPlaying] = useState(false)
  const video = data?.video
  const styles = {
    container: css`
      ${aspectRatio((video?.width || 0) / (video?.height || 0))};
    `,
    player: css``,
  }
  return (
    <div
      ref={ref}
      css={styles.container}
      data-playing={playing}
      data-inview={inView}
      {...props}
    >
      <ReactPlayer
        url={video?.url || undefined}
        light={video?.thumbnailUrl || true}
        playIcon={<VideoPlayIcon />}
        width="100%"
        height="100%"
        css={styles.player}
        onClickPreview={() => setPlaying(true)}
        playing={inView ? playing : false}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        {...playerProps}
      />
    </div>
  )
}

export const ExternalVideoBlockFragment = graphql`
  fragment ExternalVideoBlock on DatoCmsExternalVideoBlock {
    __typename
    id: originalId
    video {
      url
      thumbnailUrl
      title
      width
      height
    }
    title
  }
`
