import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'

import { DatoLink } from '@/features/links'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

export const ContactBar = (): JSX.Element => {
  const { data } = useStaticQuery<Queries.ContactBarQuery>(graphql`
    query ContactBar {
      data: datoCmsContactBar {
        heading
        ctas {
          ...ExternalLink
        }
      }
    }
  `)

  const styles = {
    section: css`
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      background: ${colors.navy};
      color: #fff;
      padding: 0.5em var(--margin);
      ${mq().ls} {
        padding: 1.5em var(--margin);
      }
      h2 {
        font-size: var(--fs-21);
        font-family: var(--ff-fairplex);
        font-weight: 400;
        text-align: center;
        line-height: 1.25;
        margin: 0.333em;
        ${mq().ml} {
          margin: 0 0 0.8em 0;
        }
      }
    `,
    buttonsWrap: css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    `,
    button: css`
      flex: none;
      font-family: var(--ff-good-extd);
      font-size: var(--fs-18);
      color: inherit;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      font-weight: 400;
      line-height: 1;
      border: 1px solid currentColor;
      padding: 1em 1.5em;
      margin: 0.5em 1em;
      cursor: pointer;
      transition: color 300ms ease;
      @media (hover: hover) {
        &:hover {
          color: ${colors.greenLight};
        }
      }
      ${mq().ml} {
        font-size: var(--fs-16);
      }
      ${mq().ms} {
        font-size: var(--fs-12);
        padding: 0.65em 0.9em;
        margin: 0.25em 0.5em;
        letter-spacing: 0.15em;
      }
    `,
  }
  return (
    <section css={styles.section}>
      <h2>{data?.heading}</h2>
      <div css={styles.buttonsWrap}>
        {data?.ctas?.map((cta, i) => (
          <DatoLink
            key={i}
            data={cta as any}
            css={styles.button}
          />
        ))}
      </div>
    </section>
  )
}
