import {
  ComponentPropsWithoutRef,
  Fragment,
  SyntheticEvent,
  useState,
} from 'react'

import { Lightbox, LightboxContentData } from '@/features/lightbox'

interface Props extends ComponentPropsWithoutRef<'a'> {
  data: LightboxContentData | null
  layout?: 'FULL' | 'CENTERED' | 'VIDEO'
}

export const LightboxLink = ({
  data,
  layout,
  children,
  ...props
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const [entry, setEntry] = useState<{
    title: string
    path: string
  } | null>(null)

  const handleOpen = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setEntry({
      title: document.title,
      path: window.location.pathname,
    })
    setTimeout(() => {
      setIsOpen(true)
    }, 10)
  }
  const slug = `/${data?.slug}/`.replace(/\/{2,}/g, '/')
  return (
    <Fragment>
      <a
        href={slug}
        onClick={handleOpen}
        css={{ textDecoration: 'none' }}
        {...props}
      >
        {children}
      </a>
      <Lightbox
        data={data}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        entry={entry}
        slug={slug}
        layout={layout}
      />
    </Fragment>
  )
}
