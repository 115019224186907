import { css } from '@emotion/react'
import { ChangeEventHandler, ComponentPropsWithoutRef } from 'react'

interface Props extends ComponentPropsWithoutRef<'div'> {
  options: {
    label?: string | null
    value?: string | null
  }[]
  value?: string
  onFilterChange: ChangeEventHandler<HTMLSelectElement>
}

export const FilterDropdown = ({
  options,
  value,
  onFilterChange,
  ...props
}: Props): JSX.Element => {
  console.log('render')
  const styles = {
    container: css`
      position: relative;
      line-height: 1;
    `,
    select: css`
      line-height: inherit;
      appearance: none;
      background: transparent;
      border: none;
      padding: 0.25em 1.2em 0.25em 0;
      margin: 0;
      color: currentColor;
      text-transform: inherit;
      text-align: inherit;
      transition: color 300ms ease;
    `,
    option: css`
      font-size: var(--fs-16);
    `,
    arrow: css`
      pointer-events: none;
      position: absolute;
      top: 0.5lh;
      right: 0;
      width: 0.8em;
      height: 0.5em;
      color: currentColor;
      transition: color 300ms ease;
    `,
  }
  return (
    <div
      css={styles.container}
      {...props}
    >
      <select
        css={styles.select}
        value={value}
        onChange={onFilterChange}
      >
        {options.map((option, i) => (
          <option
            css={styles.option}
            key={i}
            value={option.value || ''}
          >
            {option.label}
          </option>
        ))}
      </select>
      <svg
        css={styles.arrow}
        viewBox="0 0 24 14"
        fill="none"
      >
        <path
          d="M1 1L12 12L23 1"
          stroke="currentColor"
          strokeWidth="2.5"
        />
      </svg>
    </div>
  )
}
