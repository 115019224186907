import { css } from '@emotion/react'
import { ComponentPropsWithoutRef } from 'react'

import { useNavContext } from '@/contexts/nav-context'
import { ScrollToggle } from '@/features/common-blocks'
import { useEscKeyFunction } from '@/hooks/useEscKeyFunction'
import { colors } from '@/theme/variables'

type Props = ComponentPropsWithoutRef<'button'>

export const MobileNavButton = ({ ...props }: Props): JSX.Element => {
  const { mobileNavIsOpen, setMobileNavIsOpen } = useNavContext()
  const handleButtonClick = () => {
    setMobileNavIsOpen(prev => !prev)
  }
  useEscKeyFunction(() => {
    setMobileNavIsOpen(false)
  })
  const styles = {
    button: css`
      color: ${colors.navyLight};
      position: relative;
      align-self: center;
      box-sizing: content-box;
      box-sizing: border-box;
      width: 1.5em;
      height: 1.5em;
      padding: 2rem;
      margin-right: -1rem;
      border: none;
      appearance: none;
      background-color: transparent;
      cursor: pointer;
      pointer-events: all;
      z-index: 10;
      transition:
        color 300ms ease,
        opacity 300ms ease;

      span {
        position: absolute;
        height: 2px;
        width: 2rem;
        left: 50%;
        top: calc(50% - 1px);
        background-color: currentColor;
        ${!mobileNavIsOpen &&
        css`
          &:nth-of-type(1) {
            transition:
              transform 150ms ease 150ms,
              opacity 0ms ease 150ms;
            transform: translate(-50%, calc(-0.75em + 1px));
          }
          &:nth-of-type(2) {
            transition: transform 150ms ease;
            transform: translate(-50%, 0);
          }
          &:nth-of-type(3) {
            transition: transform 150ms ease;
            transform: translate(-50%, 0);
          }
          &:nth-of-type(4) {
            transition:
              transform 150ms ease 150ms,
              opacity 0ms ease 150ms;
            transform: translate(-50%, calc(0.75em - 1px));
          }
        `}

        ${mobileNavIsOpen &&
        css`
          &:nth-of-type(1) {
            transition:
              transform 150ms ease,
              opacity 0ms ease 150ms;
            transform: translate(-50%, 0);
            opacity: 0;
          }
          &:nth-of-type(2) {
            transition: transform 150ms ease 150ms;
            transform: translate(-50%, 0) rotate(45deg);
            transition-delay: 150ms;
          }
          &:nth-of-type(3) {
            transition: transform 150ms ease 150ms;
            transform: translate(-50%, 0) rotate(-45deg);
            transition-delay: 150ms;
          }
          &:nth-of-type(4) {
            transition:
              transform 150ms ease 150ms,
              opacity 0ms ease 150ms;
            transform: translate(-50%, 0);
            opacity: 0;
          }
        `}
      }
      @media (hover: hover) {
        &:hover {
          color: ${colors.navy};
        }
      }
    `,
  }
  return (
    <button
      css={styles.button}
      onClick={handleButtonClick}
      {...props}
    >
      <span />
      <span />
      <span />
      <span />
      {mobileNavIsOpen && <ScrollToggle />}
    </button>
  )
}
